import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as sentry from '@sentry/angular';

import { AppModule } from './app/app.module';
import { ENVIRONMENT } from './environments/environment';
import { registerLicense } from '@syncfusion/ej2-base';

registerLicense(ENVIRONMENT.syncfusionLicenseKey);

if (window.localStorage.getItem('theme') === 'dark-theme') {
  document.querySelector('portal-root').className += ' dark-theme';
  document.getElementsByTagName('body')[0].className += ' dark-background';
}

if (window.localStorage.getItem('theme') === 'light-theme') {
  document.getElementsByTagName('body')[0].className += ' light-background';
}

if (ENVIRONMENT.production) {
  enableProdMode();
}

// TODO Remove ie stuff as ie is dead by now
// Internet Explorer 6-11
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const isIE = /*@cc_on!@*/ !!document.documentMode;
// Edge 20+
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const isEdge = !isIE && !!window.StyleMedia;

if (isIE || isEdge) {
  console.log('IE or old Edge is not supported');
} else {
  sentry.init({
    dsn: ENVIRONMENT.sentryDSN,
    release: ENVIRONMENT.git.revision,
    tunnel: 'api/sentrytunnel',
    normalizeDepth: 11,
    tracePropagationTargets: ['http://localhost', ENVIRONMENT.prodUrl, ENVIRONMENT.betaUrl, ENVIRONMENT.reviewUrl, ENVIRONMENT.testUrl, ENVIRONMENT.stageUrl],
    integrations: [
      sentry.browserApiErrorsIntegration({
        XMLHttpRequest: false,
      }),
      sentry.extraErrorDataIntegration({ depth: 10 }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: ENVIRONMENT.sentryTracesSampleRate,
    beforeSend(error: sentry.ErrorEvent, hint: sentry.EventHint): PromiseLike<sentry.ErrorEvent | null> | sentry.ErrorEvent | null {
      if (window.location.origin === ENVIRONMENT.prodUrl || window.location.origin === ENVIRONMENT.betaUrl) {
        error.environment = 'productive';
      } else {
        error.environment = 'development';
      }

      error.server_name = window.location.hostname;

      // Workaround for Recaptcha Timeout error from the sentry guys https://github.com/getsentry/sentry-javascript/issues/2514#issuecomment-603971338
      if (hint.originalException === 'Timeout') return null;

      // Note: issue with double entries during http exceptions: https://github.com/getsentry/sentry-javascript/issues/2169
      // Note: issue with a second entry not being set correctly (as a non-error): https://github.com/getsentry/sentry-javascript/issues/2292#issuecomment-554932519
      // const isNonErrorException = error.exception.values[0].value.startsWith('Non-Error exception captured');
      // if (isNonErrorException) {
      //   if (!error.extra.__serialized__) {
      //     return null;
      //   }
      //   const serializedError = error.extra.__serialized__ as ErrorEvent;
      //   let realErrMsg = serializedError?.error ? serializedError?.error.message : null;
      //   realErrMsg = realErrMsg || serializedError?.message;
      //   // this is a useless error message that masks the actual error.  Lets try to set it properly
      //   error.exception.values[0].value = realErrMsg;
      //   error.message = realErrMsg;
      // }
      return error;
    },
  });

  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
}
